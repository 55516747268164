import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import photo1 from "../assets/images/chatbox.png";

const steps = [
   {
      id: "Greet",
      message: "Hello, Visitor",
      trigger: "Ask Name",
   },
   {
      id: "Ask Name",
      message: "What's your name?",
      trigger: "Waiting user input for name",
   },
   {
      id: "Waiting user input for name",
      user: true,
      trigger: "Hello",
   },
   {
      id: "Hello",
      message: "Hello {previousValue}! How are you?",
      trigger: "Feelings",
   },
   {
      id: "Feelings",
      user: true,
      trigger: "Done",
   },
   {
      id: "Done",
      message: "I see...Well, Have a great day !!",
      end: true,
   },
];

const theme = {
   background: "white",
   headerBgColor: "red",
   headerFontSize: "20px",
   botBubbleColor: "gray",
   headerFontColor: "white",
   botFontColor: "white",
   userBubbleColor: "black",
   userFontColor: "white",
};

const config = {
   botavatar: { photo1 },
   floating: true,
};

function Bot() {
   return (
      <div className="App">
         <ThemeProvider theme={theme}>
            <ChatBot headerTitle="Robotron" steps={steps} {...config} />
         </ThemeProvider>
      </div>
   );
}

export default Bot;
