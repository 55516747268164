import React from "react";
import "../App.css";

function Navbar() {
   return (
      <>
         <section>
            <ul>
               <li>
                  <a href="https://jpcontreras87.com/">Register</a>
               </li>
               <li>
                  <a href="https://jpcontreras87.com/">Log In</a>
               </li>

               <li>
                  <a href="dhttps://jpcontreras87.com/">Home</a>
               </li>
            </ul>
         </section>
      </>
   );
}
export default Navbar;
